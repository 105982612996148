import { FSA } from 'flux-standard-action';
import {
  CLOSE_BLOCK_MODAL,
  CLOSE_BOOKING_MODAL,
  CLOSE_CHANGE_PLAN_MODAL,
  CLOSE_CLIENT_IMPORT_MODAL,
  CLOSE_CLIENT_MODAL,
  CLOSE_HELP_MODAL,
  CLOSE_INVOICE_MESSAGE_MODAL,
  CLOSE_INVOICE_MODAL,
  CLOSE_NEW_BLOCK_MODAL,
  CLOSE_NEW_BOOKING_MODAL,
  CLOSE_NEW_BUSINESS_MODAL,
  CLOSE_NEW_INVOICE_MODAL,
  CLOSE_NEW_PAYMENT_MODAL,
  CLOSE_NEW_SYSTEM_INVOICE_MODAL,
  CLOSE_NEW_SYSTEM_PAYMENT_MODAL,
  CLOSE_PASSWORD_MODAL,
  CLOSE_PAYMENT_MODAL,
  CLOSE_SCHEDULE_MODAL,
  CLOSE_SIGN_UP,
  CLOSE_SYSTEM_INVOICE_MODAL,
  CLOSE_SYSTEM_PAYMENT_MODAL,
  CLOSE_TRIAL_END_MODAL,
  CLOSE_UPDATE_MODAL,
  CLOSE_USER_MODAL,
  DISABLE_NEW_SLOT_SELECT,
  ENABLE_NEW_SLOT_SELECT,
  NEW_SLOT_SELECTED,
  OPEN_BLOCK_MODAL,
  OPEN_BOOKING_MODAL,
  OPEN_CHANGE_PLAN_MODAL,
  OPEN_CLIENT_IMPORT_MODAL,
  OPEN_CLIENT_MODAL,
  OPEN_HELP_MODAL,
  OPEN_INVOICE_MESSAGE_MODAL,
  OPEN_INVOICE_MODAL,
  OPEN_NEW_BLOCK_MODAL,
  OPEN_NEW_BOOKING_MODAL,
  OPEN_NEW_BUSINESS_MODAL,
  OPEN_NEW_INVOICE_MODAL,
  OPEN_NEW_PAYMENT_MODAL,
  OPEN_NEW_SYSTEM_INVOICE_MODAL,
  OPEN_NEW_SYSTEM_PAYMENT_MODAL,
  OPEN_PASSWORD_MODAL,
  OPEN_PAYMENT_MODAL,
  OPEN_SCHEDULE_MODAL,
  OPEN_SIGN_UP,
  OPEN_SYSTEM_INVOICE_MODAL,
  OPEN_SYSTEM_PAYMENT_MODAL,
  OPEN_TRIAL_END_MODAL,
  OPEN_UPDATE_MODAL,
  OPEN_USER_MODAL,
  SET_CLIENT_MODAL_TAB,
} from './action-types';

import { SelectedSlotType } from './types';

export interface State {
  isUpdateModalOpen: boolean;
  isSignUpModalOpen: boolean;
  invoiceMessageModalId: string;
  invoiceModalId: string;
  invoiceModalClientId: string;
  invoiceModalBookingId: string;
  paymentModalId: string;
  paymentModalClientId: string;
  paymentModalInvoiceId: string;
  helpModalId: string;
  scheduleModalId: string;
  bookingModalId: string;
  bookingModalIsForcedModal: boolean;
  blockModalId: string;
  clientModalId: string;
  clientModalTab: 'profile' | 'bookings' | 'forms' | 'messages';
  userModalId: string;
  isNewBookingModalOpen: boolean;
  isClientImportModalOpen: boolean;
  isNewBusinessModalOpen: boolean;
  isPasswordModalOpen: boolean;
  isSelectingNewSlot: boolean;
  selectedSlot: SelectedSlotType;
  selectSlotForNewBooking: boolean;
  defaultSubject: string;
  defaultBody: string;
  bookemInvoiceModalId: string;
  bookemInvoiceModalBusinessId: string;
  bookemInvoiceModalBookingId: string;
  bookemPaymentModalId: string;
  bookemPaymentModalBusinessId: string;
  bookemPaymentModalInvoiceId: string;

  isTrialEndModalOpen: boolean;

  bookemChangePlanModalBusinessId: string;
}

const initialState: State = {
  isUpdateModalOpen: false,
  isSignUpModalOpen: false,
  invoiceMessageModalId: null,
  invoiceModalId: null,
  invoiceModalClientId: null,
  invoiceModalBookingId: null,

  paymentModalId: null,
  paymentModalClientId: null,
  paymentModalInvoiceId: null,

  helpModalId: null,
  scheduleModalId: null,
  bookingModalId: null,
  bookingModalIsForcedModal: false,
  blockModalId: null,
  clientModalId: null,
  clientModalTab: 'profile',
  userModalId: null,
  isNewBookingModalOpen: false,
  isClientImportModalOpen: false,
  isNewBusinessModalOpen: false,
  isPasswordModalOpen: false,
  isSelectingNewSlot: false,
  selectedSlot: null,
  selectSlotForNewBooking: false,
  defaultSubject: '',
  defaultBody: '',

  bookemInvoiceModalId: null,
  bookemInvoiceModalBusinessId: null,
  bookemInvoiceModalBookingId: null,
  bookemPaymentModalId: null,
  bookemPaymentModalBusinessId: null,
  bookemPaymentModalInvoiceId: null,

  isTrialEndModalOpen: false,

  bookemChangePlanModalBusinessId: null,
};

export default function reducer(
  state = initialState,
  action: FSA<any, any, any>
): State {
  switch (action.type) {
    case OPEN_TRIAL_END_MODAL:
      return {
        ...state,
        isTrialEndModalOpen: true,
      };
    case CLOSE_TRIAL_END_MODAL:
      return {
        ...state,
        isTrialEndModalOpen: false,
      };
    case OPEN_UPDATE_MODAL:
      return { ...state, isUpdateModalOpen: true };
    case CLOSE_UPDATE_MODAL:
      return { ...state, isUpdateModalOpen: false };
    case OPEN_SIGN_UP:
      return { ...state, isSignUpModalOpen: true };
    case CLOSE_SIGN_UP:
      return { ...state, isSignUpModalOpen: false };

    case OPEN_INVOICE_MESSAGE_MODAL:
      return {
        ...state,
        invoiceMessageModalId: action.payload,
      };
    case CLOSE_INVOICE_MESSAGE_MODAL:
      return {
        ...state,
        invoiceMessageModalId: null,
      };

    case OPEN_NEW_INVOICE_MODAL:
      return {
        ...state,
        invoiceModalId: 'new',
        invoiceModalClientId: action.payload.clientId,
        invoiceModalBookingId: action.payload.bookingId,
      };
    case CLOSE_NEW_INVOICE_MODAL:
      return {
        ...state,
        invoiceModalId: null,
        invoiceModalClientId: null,
        invoiceModalBookingId: null,
      };

    case OPEN_INVOICE_MODAL:
      return {
        ...state,
        invoiceModalId: action.payload,
        invoiceModalClientId: null,
        invoiceModalBookingId: null,
      };
    case CLOSE_INVOICE_MODAL:
      return {
        ...state,
        invoiceModalId: null,
        invoiceModalClientId: null,
        invoiceModalBookingId: null,
      };

    case OPEN_NEW_PAYMENT_MODAL:
      return {
        ...state,
        paymentModalId: 'new',
        paymentModalClientId: action.payload.clientId,
        paymentModalInvoiceId: action.payload.invoiceId,
      };
    case CLOSE_NEW_PAYMENT_MODAL:
      return {
        ...state,
        paymentModalId: null,
        paymentModalClientId: null,
        // paymentModalBookingId: null,
      };

    case OPEN_PAYMENT_MODAL:
      return {
        ...state,
        paymentModalId: action.payload,
        paymentModalClientId: null,
        paymentModalInvoiceId: null,
      };
    case CLOSE_PAYMENT_MODAL:
      return {
        ...state,
        paymentModalId: null,
        paymentModalClientId: null,
        paymentModalInvoiceId: null,
      };

    case OPEN_NEW_BUSINESS_MODAL:
      return {
        ...state,
        isNewBusinessModalOpen: true,
      };
    case CLOSE_NEW_BUSINESS_MODAL:
      return {
        ...state,
        isNewBusinessModalOpen: false,
      };
    case OPEN_USER_MODAL:
      return {
        ...state,
        userModalId: action.payload,
      };
    case CLOSE_USER_MODAL:
      return {
        ...state,
        userModalId: null,
      };
    case OPEN_HELP_MODAL:
      return {
        ...state,
        helpModalId: action.payload,
      };
    case CLOSE_HELP_MODAL:
      return {
        ...state,
        helpModalId: null,
      };
    case OPEN_CLIENT_MODAL:
      return {
        ...state,
        clientModalId: action.payload.id,
        clientModalTab: action.payload.tab || state.clientModalTab,
        defaultSubject: action.payload.defaultSubject,
        defaultBody: action.payload.defaultBody,
      };
    case CLOSE_CLIENT_MODAL:
      return {
        ...state,
        clientModalId: null,
        defaultSubject: '',
        defaultBody: '',
      };
    case SET_CLIENT_MODAL_TAB:
      return {
        ...state,
        clientModalTab: action.payload,
      };
    case OPEN_CLIENT_IMPORT_MODAL:
      return {
        ...state,
        isClientImportModalOpen: true,
      };
    case CLOSE_CLIENT_IMPORT_MODAL:
      return {
        ...state,
        isClientImportModalOpen: false,
      };
    case OPEN_NEW_BLOCK_MODAL:
      return {
        ...state,
        blockModalId: 'new',
        selectedSlot: action.payload,
      };
    case CLOSE_NEW_BLOCK_MODAL:
      return {
        ...state,
        blockModalId: null,
        selectedSlot: null,
      };
    case OPEN_BLOCK_MODAL:
      return {
        ...state,
        blockModalId: action.payload,
      };
    case CLOSE_BLOCK_MODAL:
      return {
        ...state,
        blockModalId: null,
        selectedSlot: null,
      };
    case OPEN_SCHEDULE_MODAL:
      return {
        ...state,
        scheduleModalId: action.payload.id,
        selectedSlot: action.payload.slot,
      };
    case CLOSE_SCHEDULE_MODAL:
      return {
        ...state,
        scheduleModalId: null,
      };
    case OPEN_PASSWORD_MODAL:
      return {
        ...state,
        isPasswordModalOpen: true,
      };
    case CLOSE_PASSWORD_MODAL:
      return {
        ...state,
        isPasswordModalOpen: false,
      };
    case OPEN_NEW_BOOKING_MODAL: {
      const { newTimeRequired, ...selectedSlot } = action.payload;
      return {
        ...state,
        isNewBookingModalOpen: true,
        bookingModalId: null,
        bookingModalIsForcedModal: false,
        selectedSlot,
        selectSlotForNewBooking: newTimeRequired,
      };
    }
    case CLOSE_NEW_BOOKING_MODAL:
      return {
        ...state,
        isNewBookingModalOpen: false,
        selectedSlot: null,
      };
    case OPEN_BOOKING_MODAL:
      return {
        ...state,
        isNewBookingModalOpen: false,
        bookingModalId: action.payload,
        bookingModalIsForcedModal: action.meta.forceModal,
        selectedSlot: null,
        // Close client modal if opened fron booking modal
        clientModalId: state.bookingModalId ? null : state.clientModalId,
      };
    case CLOSE_BOOKING_MODAL:
      return {
        ...state,
        bookingModalId: null,
        bookingModalIsForcedModal: false,
        isSelectingNewSlot: false,
      };
    case ENABLE_NEW_SLOT_SELECT:
      return {
        ...state,
        isSelectingNewSlot: true,
      };
    case NEW_SLOT_SELECTED:
      return {
        ...state,
        isSelectingNewSlot: false,
        selectSlotForNewBooking: false,
      };
    case DISABLE_NEW_SLOT_SELECT:
      return {
        ...state,
        isSelectingNewSlot: false,
        selectSlotForNewBooking: false,
      };

    case OPEN_NEW_SYSTEM_INVOICE_MODAL:
      return {
        ...state,
        bookemInvoiceModalId: 'new',
        bookemInvoiceModalBusinessId: action.payload.clientId,
        bookemInvoiceModalBookingId: action.payload.bookingId,
      };
    case CLOSE_NEW_SYSTEM_INVOICE_MODAL:
      return {
        ...state,
        bookemInvoiceModalId: null,
        bookemInvoiceModalBusinessId: null,
        bookemInvoiceModalBookingId: null,
      };

    case OPEN_SYSTEM_INVOICE_MODAL:
      return {
        ...state,
        bookemInvoiceModalId: action.payload,
        bookemInvoiceModalBusinessId: null,
        bookemInvoiceModalBookingId: null,
      };
    case CLOSE_SYSTEM_INVOICE_MODAL:
      return {
        ...state,
        bookemInvoiceModalId: null,
        bookemInvoiceModalBusinessId: null,
        bookemInvoiceModalBookingId: null,
      };

    case OPEN_NEW_SYSTEM_PAYMENT_MODAL:
      return {
        ...state,
        bookemPaymentModalId: 'new',
        bookemPaymentModalBusinessId: action.payload.businessId,
        bookemPaymentModalInvoiceId: action.payload.invoiceId,
      };
    case CLOSE_NEW_SYSTEM_PAYMENT_MODAL:
      return {
        ...state,
        bookemPaymentModalId: null,
        bookemPaymentModalBusinessId: null,
        // paymentModalBookingId: null,
      };

    case OPEN_SYSTEM_PAYMENT_MODAL:
      return {
        ...state,
        bookemPaymentModalId: action.payload,
        bookemPaymentModalBusinessId: null,
        bookemPaymentModalInvoiceId: null,
      };
    case CLOSE_SYSTEM_PAYMENT_MODAL:
      return {
        ...state,
        bookemPaymentModalId: null,
        bookemPaymentModalBusinessId: null,
        bookemPaymentModalInvoiceId: null,
      };
    case OPEN_CHANGE_PLAN_MODAL:
      return {
        ...state,
        bookemChangePlanModalBusinessId: action.payload.businessId,
      };
    case CLOSE_CHANGE_PLAN_MODAL:
      return {
        ...state,
        bookemChangePlanModalBusinessId: null,
      };
    default:
      return state;
  }
}
