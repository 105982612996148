import styled from './base';
import { tags } from './tags';

// bind it to avoid mutating the original function
// @ts-ignore
const newStyled = styled.bind();

tags.forEach((tagName) => {
  newStyled[tagName] = newStyled(tagName);
});

export default newStyled;
