export const OPEN_UPDATE_MODAL = 'global/OPEN_UPDATE_MODAL';
export const CLOSE_UPDATE_MODAL = 'global/CLOSE_UPDATE_MODAL';

export const OPEN_SIGN_UP = 'global/OPEN_SIGN_UP';
export const CLOSE_SIGN_UP = 'global/CLOSE_SIGN_UP';

export const OPEN_NEW_BUSINESS_MODAL = 'globals/OPEN_NEW_BUSINESS_MODAL';
export const CLOSE_NEW_BUSINESS_MODAL = 'globals/CLOSE_NEW_BUSINESS_MODAL';

export const OPEN_INVOICE_MESSAGE_MODAL = 'globals/OPEN_INVOICE_MESSAGE_MODAL';
export const CLOSE_INVOICE_MESSAGE_MODAL =
  'globals/CLOSE_INVOICE_MESSAGE_MODAL';

export const OPEN_INVOICE_MODAL = 'globals/OPEN_INVOICE_MODAL';
export const CLOSE_INVOICE_MODAL = 'globals/CLOSE_INVOICE_MODAL';

export const OPEN_NEW_INVOICE_MODAL = 'globals/OPEN_NEW_INVOICE_MODAL';
export const CLOSE_NEW_INVOICE_MODAL = 'globals/CLOSE_NEW_INVOICE_MODAL';

export const OPEN_PAYMENT_MODAL = 'globals/OPEN_PAYMENT_MODAL';
export const CLOSE_PAYMENT_MODAL = 'globals/CLOSE_PAYMENT_MODAL';

export const OPEN_NEW_PAYMENT_MODAL = 'globals/OPEN_NEW_PAYMENT_MODAL';
export const CLOSE_NEW_PAYMENT_MODAL = 'globals/CLOSE_NEW_PAYMENT_MODAL';

export const OPEN_USER_MODAL = 'globals/OPEN_USER_MODAL';
export const CLOSE_USER_MODAL = 'globals/CLOSE_USER_MODAL';

export const OPEN_HELP_MODAL = 'globals/OPEN_HELP_MODAL';
export const CLOSE_HELP_MODAL = 'globals/CLOSE_HELP_MODAL';

export const OPEN_CLIENT_MODAL = 'globals/OPEN_CLIENT_MODAL';
export const CLOSE_CLIENT_MODAL = 'globals/CLOSE_CLIENT_MODAL';

export const SET_CLIENT_MODAL_TAB = 'globals/SET_CLIENT_MODAL_TAB';

export const OPEN_CLIENT_IMPORT_MODAL = 'globals/OPEN_CLIENT_IMPORT_MODAL';
export const CLOSE_CLIENT_IMPORT_MODAL = 'globals/CLOSE_CLIENT_IMPORT_MODAL';

export const OPEN_BOOKING_MODAL = 'globals/OPEN_BOOKING_MODAL';
export const CLOSE_BOOKING_MODAL = 'globals/CLOSE_BOOKING_MODAL';

export const OPEN_NEW_BOOKING_MODAL = 'globals/OPEN_NEW_BOOKING_MODAL';
export const CLOSE_NEW_BOOKING_MODAL = 'CLOSE_NEW_BOOKING_MODAL';

export const OPEN_SCHEDULE_MODAL = 'globals/OPEN_SCHEDULE_MODAL';
export const CLOSE_SCHEDULE_MODAL = 'globals/CLOSE_SCHEDULE_MODAL';

export const OPEN_BLOCK_MODAL = 'globals/OPEN_BLOCK_MODAL';
export const CLOSE_BLOCK_MODAL = 'globals/CLOSE_BLOCK_MODAL';

export const OPEN_NEW_BLOCK_MODAL = 'globals/OPEN_NEW_BLOCK_MODAL';
export const CLOSE_NEW_BLOCK_MODAL = 'globals/CLOSE_NEW_BLOCK_MODAL';

export const OPEN_PASSWORD_MODAL = 'globals/OPEN_PASSWORD_MODAL';
export const CLOSE_PASSWORD_MODAL = 'globals/CLOSE_PASSWORD_MODAL';

export const ENABLE_NEW_SLOT_SELECT = 'globals/ENABLE_NEW_SLOT_SELECT';
export const NEW_SLOT_SELECTED = 'globals/NEW_SLOT_SELECTED';
export const DISABLE_NEW_SLOT_SELECT = 'globals/DISABLE_NEW_SLOT_SELECT';

export const OPEN_SYSTEM_INVOICE_MODAL = 'globals/OPEN_SYSTEM_INVOICE_MODAL';
export const CLOSE_SYSTEM_INVOICE_MODAL = 'globals/CLOSE_SYSTEM_INVOICE_MODAL';

export const OPEN_NEW_SYSTEM_INVOICE_MODAL =
  'globals/OPEN_NEW_SYSTEM_INVOICE_MODAL';
export const CLOSE_NEW_SYSTEM_INVOICE_MODAL =
  'globals/CLOSE_NEW_SYSTEM_INVOICE_MODAL';

export const OPEN_SYSTEM_PAYMENT_MODAL = 'globals/OPEN_SYSTEM_PAYMENT_MODAL';
export const CLOSE_SYSTEM_PAYMENT_MODAL = 'globals/CLOSE_SYSTEM_PAYMENT_MODAL';

export const OPEN_NEW_SYSTEM_PAYMENT_MODAL =
  'globals/OPEN_NEW_SYSTEM_PAYMENT_MODAL';
export const CLOSE_NEW_SYSTEM_PAYMENT_MODAL =
  'globals/CLOSE_NEW_SYSTEM_PAYMENT_MODAL';

export const OPEN_TRIAL_END_MODAL = 'globals/OPEN_TRIAL_END_MODAL';
export const CLOSE_TRIAL_END_MODAL = 'globals/CLOSE_TRIAL_END_MODAL';

export const OPEN_CHANGE_PLAN_MODAL = 'globals/OPEN_CHANGE_PLAN_MODAL';
export const CLOSE_CHANGE_PLAN_MODAL = 'globals/CLOSE_CHANGE_PLAN_MODAL';
