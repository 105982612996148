import '@emotion/react';

import Color from 'color';
import hsluv, { ColorTuple } from 'hsluv';
// import { createPaletteFromColor } from 'palettey';

const INPUT_TRANSITION = 'box-shadow 0.1s, border-color 0.1s ease-in-out';

const TYPESCALE = 1.25;
const TYPESCALE_START = -2;
const FONT_SIZES = 8;
export const BASE_FONT_SIZE = 14;

export const pxToEm = (px: number) => `${px / BASE_FONT_SIZE}em`;
export const pxToRem = (px: number) => `${px / BASE_FONT_SIZE}rem`;
const toPx = (px) => `${px}px`;

const progression = [
  95.3, 89.0, 78.9, 67.6, 57.6, 49.9, 40.1, 30.1, 20.0, 10.0,
];

// BASE NEUTRALS
// --neutral---black: #1C1B1C;
// --neutral---white: #FFFFFF;
// --neutral---background: #F3F3F6;

const black = '#1C1B1C';
const white = '#FFFFFF';
const background = '#F3F3F6';

// GREYS
const gray = [
  '#F5F5F5',
  '#E3E2E3',
  '#C7C6C7',
  '#ACABAD',
  '#919093',
  '#78767A',
  '#5F5E60',
  '#414042',
  '#313032',
  '#2A2A2B',
  // '#1C1B1C',
];

// PRIMARY PURPLE
const primaryPurple = [
  '#F5F5FD',
  '#F2F0FF',
  '#E1DCFA',
  '#C7BCF7',
  '#AA98F2',
  '#907AE6',
  '#7B63DC',
  '#6443D1',
  '#4C2EA7',
  '#341E77',
  '#1E0F4B',
  // '#10092A',
];

// SECONDARY ORANGE
const orange = [
  '#FFF0E5',
  '#FFE0CC',
  '#FFC299',
  '#FFA061',
  '#FF822E',
  '#FA6400',
  '#C75000',
  '#943B00',
  '#662900',
  '#331400',
  // '#190A00',
];

// RED DANGER
const danger = [
  '#FEE7ED',
  '#FCCFDA',
  '#F99FB5',
  '#F76E90',
  '#F43E6C',
  '#F10F46',
  '#C10B39',
  '#91082A',
  '#60061C',
  '#30030E',
  // '#180107',
];

const warning = orange;

// GREEN SUCCESS
const success = [
  '#EBF9F0',
  '#D7F4E2',
  '#AFE9C4',
  '#87DEA7',
  '#5FD38A',
  '#37C76C',
  '#2CA057',
  '#217841',
  '#16502B',
  '#0B2816',
  // '#06140B',
];
// BLUE INFO
const info = [
  '#E7F0FE',
  '#CFE0FC',
  '#9FC1F9',
  '#73A6F7',
  '#4285F4',
  '#1368F1',
  '#0C53C6',
  '#083D91',
  '#062860',
  '#031430',
  // '#010A18',
];

// YELLOW SPECIAL
const yellow = [
  '#FFF9E5',
  '#FFF4CC',
  '#FFE999',
  '#FFDF6B',
  '#FFD438',
  '#FFCA06',
  '#D1A400',
  '#9E7C00',
  '#665000',
  '#332800',
  // '#191400',
];

// BRAND COLOURS
// --brand---amethyst: #7B63DC;
// --brand---tangerine: #FA6400;
// --brand---yellow: #FFCA06;
// --brand---indigo: #392C75;

export const toLuv = (color) => {
  // const p = createPaletteFromColor('colors', color, {
  //   useLightness: false,
  // });

  // const colors = Object.values(p.colors);
  // console.log(colors);
  // return colors;

  const [h, s, v] = hsluv.hexToHsluv(color);
  const colors = new Array(10)
    .fill(0)
    .map((_, idx) => [h, s, progression[idx]] as ColorTuple);

  let closestColorIdx = -1;
  let closestDist = 999999;
  for (let i = 0; i < 10; i++) {
    const dist = Math.abs(colors[i][2] - v);

    if (closestColorIdx === -1 || dist < closestDist) {
      closestColorIdx = i;
      closestDist = dist;
    }
  }

  return colors.map((v, idx) =>
    closestColorIdx === idx ? color : hsluv.hsluvToHex(v)
  );
};

// const brand0 = [
//   '#f2f0ff',
//   '#e1dcfa',
//   '#c7bcf7',
//   '#aa98f2',
//   '#907ae6',
//   '#7b63dc',
//   '#6443d1',
//   '#4c2ea7',
//   '#341e77',
//   '#1e0f4b',
// ];

// const brand1 = [
//   '#ffedea',
//   '#ffdcd4',
//   '#ffbb9e',
//   '#ff8547',
//   '#fa6400',
//   '#cb5000',
//   '#a33e00',
//   '#7c2e00',
//   '#581e00',
//   '#360f00',
// ];
// const brand2 = [
//   '#fff5e6',
//   '#ffeac2',
//   '#ffda85',
//   '#ffca06',
//   '#dfb000',
//   '#be9600',
//   '#9f7d00',
//   '#816400',
//   '#644d00',
//   '#483700',
// ];

// const brand3 = [
//   '#f1f0f9',
//   '#e3e0f3',
//   '#c7c2e8',
//   '#aca5dd',
//   '#9187d2',
//   '#7b6dc6',
//   '#6454b8',
//   '#4f3e9d',
//   '#392c75',
//   '#22194b',
// ];
// const gray = [
//   '#f5f5f5',
//   '#e3e2e3',
//   '#c7c6c7',
//   '#acabad',
//   '#919093',
//   '#78767a',
//   '#5f5e60',
//   '#414042',
//   '#313032',
//   '#1c1b1c',
// ];

// const brand = {
//   0: brand3[8], // indigo
//   1: brand1[4], // tangerine brand1.4
//   2: brand2[3], // yellow
//   3: brand0[5],
//   4: gray[7], // gray
// };

// const brand = {
//   0: '#392c75', // indigo
//   1: '#FA6400', // tangerine
//   2: '#FFCA06', // yellow
//   3: '#7b63dc',
//   4: '#414042', // gray
// };

// const brand0 = toLuv(brand[0]);
// const brand1 = toLuv(brand[1]);
// const brand2 = toLuv(brand[2]);
// const brand3 = toLuv(brand[3]);
// const gray = toLuv(brand[4]);

const brand0 = primaryPurple;
const brand1 = orange;
const brand2 = yellow;
const brand3 = primaryPurple;

export const baseColors = {
  secondary: gray[1],

  // success: '#00ab47',
  success: success[6],
  // warning: '#fa6400',
  warning: warning[5],
  // alert: '#ff1940',
  alert: danger[5],
  info: info[5],
  // info: '#4285f4',

  // black: '#222222',
  black: gray[10],
  white: '#fefefe',

  background: '#fefefe',
  bodyBackground: '#f3f3f6',

  text: '#222222',
  invertedText: '#fefefe',
  darkText: '#000000',
  invertedDarkText: '#ffffff',
  gray,
  brand0,
  brand1,
  brand2,
  brand3,
};

// const getDarkColorScale = (colors) => [
//   ...[...colors].reverse().filter((_, idx) => idx !== 0),
//   colors[0],
// ];

const getDarkColorScale = (colors) => [...colors].reverse();

export const addDerivatives = (theme) => {
  const colors = theme.colors;

  const shadowRgb = '27, 31, 35';
  const darkShadowRgb = '0, 0, 0';

  const lightShadows = {
    small: `0 1px 1px rgba(${shadowRgb}, 0.1)`,
    medium: `0 1px 5px rgba(${shadowRgb}, 0.15)`,
    large: `0 1px 15px rgba(${shadowRgb}, 0.15)`,
    extraLarge: `0 10px 50px rgba(${shadowRgb}, 0.07)`,
    formControl: `rgba(${shadowRgb}, 0.075) 0px 1px 2px inset`,
    elevated: 'box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15)',
    popup: '2px 2px 8px rgba(0, 0, 0, 0.5)',
    slateFocus: `0 0 0 2px ${colors.brand0[3]}`,
    image: '0px 11px 30px 5px #c1c1c1',
    eventOverlap: '-1px -1px rgba(255, 255, 255, 0.5)',
    columnLabels: '0 5px 5px -3px rgba(27, 31, 35, 0.15)',
    rowLabels: '0 5px 5px 2px rgba(27, 31, 35, 0.15)',
  };

  const lightColors = {
    ...colors,
    anchor: colors.brand0[7],
    anchorHover: colors.brand0[8],
    borderColor: colors.gray[1],
    accordionBackground: colors.gray[0],
    accordionActiveBackground: colors.gray[1],
    defaultInputBackground: colors.gray[0],
    defaultInputFocusBackground: colors.background,
    defaultInputBorder: colors.gray[0],
    defaultInputFocusBorder: colors.brand0[3],
    transparentHeaderLogoColor: colors.brand3[8],

    primary: colors.brand3[8],
    // gray: colors.gray[7],
    primary_: colors.brand0,
    // green: toLuv(colors.success),
    // yellow: toLuv(colors.warning),
    // red: toLuv(colors.alert),
    green: success,
    yellow: warning,
    red: danger,

    invalidInputBackground: `${Color(colors.white)
      .mix(Color(colors.alert), 0.1)
      .string()}`,

    loaderColors: [colors.brand0[5], colors.brand1[4], colors.brand2[3]],
    sidePanel: colors.gray[9],
    sidePanelBorder: colors.gray[9],
    sidePanelHighlight: colors.gray[7],
    sidePanelSub: colors.gray[9],
    sidePanelSubHighlight: colors.gray[7],

    // brand0: colors.brand0,
    // brand1: colors.brand1,
    // brand2: colors.brand2,
    // brand3: colors.brand3,
  };

  return {
    ...theme,
    colors: lightColors,
    shadows: lightShadows,
    mode: 'light',
    modes: {
      dark: {
        colors: {
          // alert: toLuv(colors.alert).reverse()[8],
          text: colors.invertedText,
          invertedText: colors.text,
          darkText: colors.invertedDarkText,
          invertedDarkText: colors.darkText,
          anchor: colors.brand0[2],
          anchorHover: colors.brand0[1],
          background: colors.gray[8],
          bodyBackground: colors.gray[9],
          secondary: colors.gray[8],
          borderColor: colors.gray[7],

          accordionBackground: colors.gray[8],
          accordionActiveBackground: colors.gray[7],
          defaultInputBackground: colors.gray[7],
          defaultInputFocusBackground: colors.gray[8],
          defaultInputBorder: colors.gray[7],
          defaultInputFocusBorder: colors.brand0[3],

          transparentHeaderLogoColor: 'white',

          // primary: colors.brand3[4],
          primary: colors.brand3[2],

          gray: getDarkColorScale(lightColors.gray),
          primary_: getDarkColorScale(lightColors.brand0),
          green: getDarkColorScale(lightColors.green),
          yellow: getDarkColorScale(lightColors.yellow),
          red: getDarkColorScale(lightColors.red),

          invalidInputBackground: `${Color(colors.black)
            .mix(Color(colors.alert), 0.1)
            .string()}`,

          sidePanelBorder: gray[7],
          // sidePanel: colors.gray[9],
          // sidePanelBorder: colors.gray[9],
          // sidePanelHighlight: colors.gray[7],
          // sidePanelSub: colors.gray[8],
          // sidePanelSubHighlight: colors.gray[7],

          brand0: getDarkColorScale(lightColors.brand0),
          brand1: getDarkColorScale(lightColors.brand1),
          brand2: getDarkColorScale(lightColors.brand2),
          brand3: getDarkColorScale(lightColors.brand3),
        },
        shadows: {
          small: `0 1px 1px rgba(${darkShadowRgb}, 0.1)`,
          medium: `0 1px 5px rgba(${darkShadowRgb}, 0.15)`,
          large: `0 1px 15px rgba(${darkShadowRgb}, 0.15)`,
          extraLarge: `0 10px 50px rgba(${darkShadowRgb}, 0.07)`,
          formControl: `rgba(${darkShadowRgb}, 0.075) 0px 1px 2px inset`,
          elevated: 'box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15)',
          popup: '2px 2px 8px rgba(0, 0, 0, 0.5)',
          slateFocus: `0 0 0 2px ${colors.brand0[7]}`,
          image: '0px 11px 30px 5px #1c1c1c',
          eventOverlap: '-1px -1px rgba(0, 0, 0, 0.5)',
          columnLabels: '0 5px 5px -3px rgba(0, 0, 0, 0.3)',
          rowLabels: '0 5px 5px 2px rgba(0, 0, 0, 0.3)',
        },
        mode: 'dark',
      },
      light: {
        colors: lightColors,
        shadows: lightShadows,
        mode: 'light',
      },
    },
  };
};

const colors = baseColors;

const getPxScaleValue = (scaleIdx) =>
  BASE_FONT_SIZE * Math.pow(TYPESCALE, scaleIdx);

const fontScale = new Array<number>(FONT_SIZES)
  .fill(0)
  .map((_, idx) => idx + TYPESCALE_START);

export const desktopfontSizes = fontScale
  .map(getPxScaleValue)
  .map(Math.round) // Fonts render better when they don't span pixel boundaries
  .map(toPx);

export const mobileFontSizes = fontScale
  .map(getPxScaleValue)
  .map((v) => v * (16 / 14))
  .map(Math.round) // Fonts render better when they don't span pixel boundaries
  .map(toPx);

export const wixMobileFontSizes = fontScale
  .map(getPxScaleValue)
  .map((v) => v * (13 / 14))
  .map(Math.round) // Fonts render better when they don't span pixel boundaries
  .map(toPx);

const fontSizes = fontScale.map((_, idx) => `var(--font-size${idx})`);

const breakpointsPx = [768, 1024, 1200];
const breakpoints = breakpointsPx.map((v) => toPx(v)) as any;

const theme = addDerivatives({
  name: 'Bookem',
  breakpointsPx,
  breakpoints,
  mediaQueries: {
    smallOnly: `screen and (max-width: ${toPx(breakpointsPx[0] - 1)})`,
    mobileDown: `screen and (max-width: ${toPx(breakpointsPx[0] - 1)})`,

    mediumOnly: `screen and (min-width: ${toPx(
      breakpointsPx[0]
    )}) and (max-width: ${toPx(breakpointsPx[1] - 1)})`,
    mediumUp: `screen and (min-width: ${toPx(breakpointsPx[0])})`,
    mediumDown: `screen and (max-width: ${toPx(breakpointsPx[1] - 1)})`,

    largeOnly: `screen and (min-width: ${toPx(
      breakpointsPx[1]
    )}) and (max-width: ${toPx(breakpointsPx[2] - 1)})`,
    largeUp: `screen and (min-width: ${toPx(breakpointsPx[1])})`,
    largeDown: `screen and (max-width: ${toPx(breakpointsPx[1] - 1)})`,

    xLargeDown: `screen and (max-width: ${toPx(breakpointsPx[2] - 1)})`,
    xLargeOnly: `screen and (min-width: ${toPx(breakpointsPx[2])})`,
    xLargeUp: `screen and (min-width: ${toPx(breakpointsPx[2])})`,

    mediumToLarge: `screen and (min-width: ${
      breakpointsPx[0]
    }px) and (max-width: ${breakpointsPx[2] - 1}px)`,
  },
  colors,
  fonts: {
    body: "'Open Sans', sans-serif",
    heading: 'Montserrat, sans-serif',
  },
  radii: [0, 2, 4, 8],
  shadows: {},
  fontSizes,
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.3,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  topbar: { height: 48 },
  maxGridWidth: '1200px',

  // variants
  text: {
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    link: {
      color: 'anchor',
      '&:hover': {
        color: 'anchorHover',
      },
      '&:focus': {
        color: 'anchorHover',
        textDecoration: 'underline',
      },
    },
    underlinedLink: {
      color: 'inherit',
      textDecoration: 'underline',
    },
    discreetLink: {
      color: 'darkText',
      fontWeight: 'body',
      '&:hover': {
        color: 'anchorHover',
      },
      '&:focus': {
        textDecoration: 'underline',
        outline: 'none',
      },
    },
    label: {
      fontFamily: 'inherit',
      lineHeight: 'body',
      fontWeight: 'heading',
    },
  },

  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle',
    },
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 'card',
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary',
      },
    },
  },

  inputs: {
    default: {
      appearance: 'none',
      display: 'block',
      boxSizing: 'border-box',
      width: '100%',
      padding: `8px 12px`,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: 2,
      borderColor: 'defaultInputBorder',
      backgroundColor: 'defaultInputBackground',
      fontFamily: 'body',
      fontSize: 2,
      fontWeight: 'normal',
      lineHeight: 'body',
      color: 'text',
      transition: INPUT_TRANSITION,

      '&:focus, &.focus, &:focus-within': {
        outline: 'none',
        borderStyle: 'solid',
        borderColor: 'defaultInputFocusBorder',
        backgroundColor: 'defaultInputFocusBackground',
        transition: INPUT_TRANSITION,
      },

      '&:disabled': {
        backgroundColor: 'gray.1',
        cursor: 'not-allowed',
      },

      '::placeholder': {
        color: 'gray.4',
      },
    },
    outlined: {
      appearance: 'none',
      display: 'block',
      boxSizing: 'border-box',
      width: '100%',
      padding: `8px 12px`,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: 2,
      borderColor: 'gray.2',
      backgroundColor: 'background',
      fontFamily: 'body',
      fontSize: 2,
      fontWeight: 'normal',
      lineHeight: 'body',
      color: 'text',
      transition: INPUT_TRANSITION,

      '&:focus, &.focus, &:focus-within': {
        outline: 'none',
        borderStyle: 'solid',
        borderColor: 'defaultInputFocusBorder',
        backgroundColor: 'background',
        transition: INPUT_TRANSITION,
      },

      '&:disabled': {
        backgroundColor: 'gray.1',
        cursor: 'not-allowed',
      },

      '::placeholder': {
        color: 'gray.3',
      },
    },
  },

  buttons: {
    primary: {
      fontFamily: 'heading',
      fontWeight: 500,
    },
    solid: {
      variant: 'buttons.primary',
    },
    secondary: {
      variant: 'buttons.primary',
    },
    outlined: {
      variant: 'buttons.primary',
    },
    flat: {
      variant: 'buttons.primary',
    },
  },

  // base theme values...
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
});

export default theme;
export type ThemeType = typeof theme;
// export default styled as CreateStyled<Theme>
