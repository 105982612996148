import { combineEpics } from 'redux-observable';
import toasterEpic from 'shared/toaster/redux/epics';
import globalsEpic from 'shared/redux/globals/epics';
import sessionEpic from 'shared/redux/session/epics';

const getRootEpic = () => {
  return combineEpics(toasterEpic, globalsEpic, sessionEpic);
};

export default getRootEpic;
