import React from 'react';
import { Box, BoxProps } from '../grid';

export const trimTextNode = ({ lineHeight }: { lineHeight: number }) => {
  const descenderHeightScale = 0.13;
  const capHeightScale = 0.77;

  const typeOffset = (lineHeight - 1) / 2 + descenderHeightScale;
  const heightCorrection = lineHeight - capHeightScale;
  const preventCollapse = 1;

  return {
    paddingTop: `${preventCollapse}px`,
    /* baseline on bottom of container */
    transform: `translateY(${typeOffset}em)`,
    /* crop the top of the text node */
    '&:before': {
      content: '""',
      marginTop: `calc(${-heightCorrection}em - ${preventCollapse}px)`,
      display: 'block',
      height: 0,
    },
  };
};

export function CroppedText({
  ref,
  ...props
}: BoxProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      ref={ref}
      __css={{
        fontFamily: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'body',
        fontSize: 2,
        ...trimTextNode({ lineHeight: Number(props.lineHeight) || 1.6 }),
      }}
      {...props}
    />
  );
}

export function Text({
  ref,
  ...props
}: BoxProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      ref={ref}
      __css={{
        fontFamily: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'body',
        fontSize: 2,
        wordBreak: 'break-word',
      }}
      {...props}
    />
  );
}

export function Heading({
  ref,
  ...props
}: BoxProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      ref={ref}
      as="h2"
      tx="text"
      variant="heading"
      {...props}
      __css={{
        fontSize: 4,
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        color: 'inherit',
      }}
    />
  );
}

export function Link({
  ref,
  ...props
}: BoxProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      ref={ref}
      as="a"
      tx="text"
      variant="link"
      __css={{
        fontFamily: 'inherit',
        fontWeight: 'heading',
        fontSize: 'inherit',
        lineHeight: 'body',
        display: 'inline',
        textAlign: 'inherit',
      }}
      {...props}
    />
  );
}

export const Anchor = Link;
