import { CacheProvider, ThemeProvider } from '@emotion/react';
import { MDXProvider } from '@mdx-js/react';
import { Settings as LuxonSettings } from 'luxon';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { ClientHiddenStyle } from 'shared/client-only';
import components from 'shared/mdx-components';
import { ModalToastProvider } from 'shared/modal/context';
import { getBrowserTimezone } from 'shared/utils/time';
import 'what-input';
import summaryImageLarge from '../public/images/og.jpg';
import { cache } from '../shared/emotion';
import { useStore } from '../store';
import theme from '../theme';
import qs from 'query-string';
import { setCookie } from 'shared/utils/cookies';
// import getConfig from 'next/config';
// const { serverRuntimeConfig } = getConfig();

if (!Object.fromEntries) {
  Object.fromEntries = function (iterable) {
    // Assume the input is either an iterable object or an array-like object
    return Array.from(iterable).reduce(function (obj, entry) {
      // https://github.com/tc39/proposal-object-from-entries/blob/e4837799c1586a07c101570b27997497e5290c22/polyfill.js#L9-L10
      // contract is that entry has "0" and "1" keys, not that it is an array or iterable.
      obj[entry[0]] = entry[1];
      return obj;
    }, {});
  };
}

const tagline = 'Simple booking and client management';
const description =
  'Take bookings online, issue invoices and receive payments. Client profiles can be fully customised and updated by sending forms. Video meetings are built in.';

if (typeof window !== 'undefined') {
  // if (process.env.NODE_ENV !== 'production') {
  //   const whyDidYouRender = require('@welldone-software/why-did-you-render');
  //   whyDidYouRender(React);
  // }

  const searchString = qs.parse(window.location.search);

  const cookifyQs = {
    aid: 'affiliate_id',
    oc: 'offer_code',
    utm_content: 'utm_content',
    utm_campaign: 'utm_campaign',
    utm_medium: 'utm_medium',
    utm_source: 'utm_source',
    utm_term: 'utm_term',
  };

  for (const [qsKey, cookieKey] of Object.entries(cookifyQs)) {
    const value = Array.isArray(searchString[qsKey])
      ? searchString[qsKey][0]
      : searchString[qsKey];

    if (value) {
      setCookie(cookieKey, value);
    }
  }

  const confLuxon = () => {
    LuxonSettings.defaultZone = getBrowserTimezone();
    LuxonSettings.throwOnInvalid = true;
  };
  setTimeout(confLuxon);
  // Disable back swipe
  document.body.style.overscrollBehaviorX = 'none';

  setTimeout(() => {
    if (window.location.pathname.startsWith('/room/')) {
      return;
    }

    window['MissiveChatConfig'] = {
      id: '80fab46f-ddf8-4bea-ac10-dcaf3db30a5f',
    };

    const s = document.createElement('script');
    s.async = true;
    s.src =
      'https://webchat.missiveapp.com/' +
      window['MissiveChatConfig'].id +
      '/missive.js';
    if (document.head) document.head.appendChild(s);
  });

  // if (process.env.NODE_ENV === 'production') {
  //   /*eslint-disable */
  //   window['heap'] = window['heap'] || [];
  //   window['heap'].load = function (e, t) {
  //     (window['heap'].appid = e), (window['heap'].config = t = t || {});
  //     var r = t.forceSSL || 'https:' === document.location.protocol,
  //       a = document.createElement('script');
  //     (a.type = 'text/javascript'),
  //       (a.async = !0),
  //       (a.src =
  //         (r ? 'https:' : 'http:') +
  //         '//cdn.heapanalytics.com/js/heap-' +
  //         e +
  //         '.js');
  //     var n = document.getElementsByTagName('script')[0];
  //     n.parentNode.insertBefore(a, n);
  //     for (
  //       var o = function (e) {
  //           return function () {
  //             window['heap'].push(
  //               [e].concat(Array.prototype.slice.call(arguments, 0))
  //             );
  //           };
  //         },
  //         p = [
  //           'addEventProperties',
  //           'addUserProperties',
  //           'clearEventProperties',
  //           'identify',
  //           'removeEventProperty',
  //           'setEventProperties',
  //           'track',
  //           'unsetEventProperty',
  //         ],
  //         c = 0;
  //       c < p.length;
  //       c++
  //     )
  //       window['heap'][p[c]] = o(p[c]);
  //   };
  //   window['heap'].load(process.env.HEAP_CLIENT_ID);
  //   /*eslint-enable */
  // }
}

function MyApp({ Component, pageProps }: AppProps) {
  // console.log({pageProps})
  const store = useStore(pageProps['initialReduxState']);
  const persistor = persistStore(store, {}, function () {
    persistor.persist();
  });

  // const checkVersion = React.useCallback(() => {
  //   axios
  //     .get('/version.json')
  //     .then(({ data }) => data.hash && __VERSION__ !== data.hash) // eslint-disable-line no-undef
  //     .then((hasNew) => hasNew && store.dispatch(openUpdateModal()));
  // }, [store]);

  // React.useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') {
  //     checkVersion();
  //     setInterval(checkVersion, 60000);
  //   }
  // }, [checkVersion]);

  return (
    <Provider store={store}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <ClientHiddenStyle />
          <MDXProvider components={components}>
            <ModalToastProvider>
              <DefaultSeo
                title={tagline}
                titleTemplate={`Bookem | %s`}
                description={description}
                openGraph={{
                  type: 'website',
                  locale: 'en',
                  url: 'https://bookem.com/',
                  site_name: 'Bookem',
                  images: [
                    {
                      url: `https://bookem.com${summaryImageLarge.src}`,
                      width: 1200,
                      height: 630,
                    },
                  ],
                }}
                twitter={{
                  handle: '@bookemapp',
                  site: '@bookemapp',
                  cardType: 'summary_large_image',
                }}
              />
              <Component {...pageProps} />
            </ModalToastProvider>
          </MDXProvider>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  );
}
export default MyApp;
