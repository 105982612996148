import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, combineReducers, Dispatch } from 'redux';
import { configureStore, Tuple, EnhancedStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
// import { persistReducer } from 'redux-persist';
import { ThunkDispatch, thunk } from 'redux-thunk';
// import createWebStorage from 'shared/redux-storage';
import geoipReducer from 'shared/redux/geoip/reducer';
import globalsReducer from 'shared/redux/globals/reducer';
import pricingReducer from 'shared/redux/pricing/reducer';
import sessionReducer from 'shared/redux/session/reducer';
// import userPreferencesReducer from 'shared/redux/user-preferences/reducer';
import systemPlansReducer from 'shared/redux/system-plans/reducer';
import toasterReducer from 'shared/toaster/redux/reducer';
import getRootEpic from './root-epic';

let store;
// const isServer = typeof window === 'undefined';

// const persistReducerFn: <T>(_: any, reducer: T) => T = isServer
//   ? (_, reducer) => reducer
//   : (persistReducer as any);

// const reduxStore = createWebStorage();

// export type RootState = EnhancedStore<typeof reducer>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = Dispatch<Action<any>> &
  ThunkDispatch<RootState, null, Action<any>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function makeStore(initialState = {}) {
  const reducer = combineReducers({
    session: sessionReducer,
    // session: persistReducerFn(
    //   { key: 'session', storage: reduxStore },
    //   sessionReducer
    // ) as typeof sessionReducer,
    // userPreferences: persistReducerFn(
    //   { key: 'userPreferences', storage: reduxStore },
    //   userPreferencesReducer
    // ),
    toaster: toasterReducer,
    geoip: geoipReducer,
    pricing: pricingReducer,
    globals: globalsReducer,
    plans: systemPlansReducer,
  });

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

  const epicMiddleware = createEpicMiddleware<any, any>();

  const store = configureStore({
    reducer,
    preloadedState: initialState,
    middleware: () => new Tuple(thunk, epicMiddleware),
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
  });

  epicMiddleware.run(getRootEpic());

  return store;

  // export const persistor = persistStore(store);
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
