import React from 'react';
import { Global } from 'shared/styled/global';

export function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}

export default function ClientOnly({ children }) {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  return children;
}

export const ClientHiddenStyle = () => {
  const isMounted = useHasMounted();
  return (
    <Global
      styles={{
        '.client-side-hidden': {
          visibility: isMounted ? 'hidden' : undefined,
        },
      }}
    />
  );
};
